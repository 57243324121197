import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { concat, from, Observable } from 'rxjs';
import { mimeTypes } from 'mime-wrapper';

import { OverlayComponent } from 'src/app/components/common/overlay/overlay';

import { StepperDtlService } from 'src/app/services/stepper-dtl.service';
import { ProductService } from 'src/app/services/product.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FormService } from 'src/app/services/form.service';
import { AutocompleteService } from 'src/app/services/autocomplete.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { APICallService } from 'src/app/services/api-call.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { SecurityService } from 'src/app/services/security.service';
import { DownloadService } from 'src/app/services/download.service';
import { PopupService } from 'src/app/services/popup.service';
import { FacebookEventAnalyticsService } from 'src/app/services/fbq.service';

declare function setInputFormatter(): any;

@Component({
    templateUrl: './vehicle-details.html',
})
export class VehicleDetailsComponent implements OnInit {

    form: FormGroup = this.formBuilder.group({
        mortgageType: [""],
        bankDetail: [""],
        loanAccountNo: [""],
        alfesNo: [""],
        vehicleType: [""],
        plateNo: [""],
        yearModelDetail: ["", [this.formService.autocomplete(), Validators.required]],
        makeDetail: [{ value: "", disabled: true }, [this.formService.autocomplete(), Validators.required]],
        modelDetail: [{ value: "", disabled: true }, [this.formService.autocomplete(), Validators.required]],
        subModelDetail: [{ value: "", disabled: true }, [this.formService.autocomplete(), Validators.required]],
        makeTypeCd: [""],
        ccWt: [{ value: "", disabled: true }],
        gasType: [""],
        gasDesc: [{ value: "", disabled: true }],
        noOfSeats: [{ value: "", disabled: true }],
        fmvMinValue: [""],
        fmvMaxValue: [""],
        seatsCovered: [{ value: "", disabled: true }],
        color: [""],
        purchasePrice: [{ value: "", disabled: true }],
        engineNo: [""],
        chassisNo: [""],
        beingUsedDetail: [{ value: "", disabled: true }, [this.formService.autocomplete()]],
        mvFileNo: [""],
        mvTypeDetail: [{ value: "", disabled: false }, [this.formService.autocomplete()]],
        mvPremTypeDetail: [{ value: "", disabled: false }, [this.formService.autocomplete()]],
        remarks: [""],
        supportingDocs: [[]],
        genPageWord: [""],

    });

    // AUTO-COMPLETE OPTIONS
    filteredBankDetailOptions: Observable<any>;
    filteredYearModelDetailOptions!: Observable<any>;
    filteredMakeDetailOptions: Observable<any> = new Observable;
    filteredModelDetailOptions: Observable<any> = new Observable;
    filteredSubModelDetailOptions: Observable<any> = new Observable;
    filteredBeingUsedDetailOptions: Observable<any> = new Observable;
    filteredMvTypeOptions: Observable<any> = new Observable;
    filteredMvPremTypeOptions: Observable<any> = new Observable;

    // SHOW DEPENDENT FIELDS
    showVehicleMortgageDependents: boolean = false;
    showBankNameDependent: boolean = false;
    showVehicleTypeDependent: boolean = false;

    // PARAMETERS TO DETERMINE IF SPECIFIC FIELD IS REQUIRED
    reqMcBank: string = "";
    reqMcLan: string = "";
    reqMcAlfesNo: string = "";
    reqMcPlateNo: string = "";
    reqMcSeats: string = "";
    reqMcColor: string = "";
    reqMcEngine: string = "";
    reqMcChassis: string = "";
    reqMcUsage: string = "";
    reqMvFileNo: string = "";
    reqMvType: string = "";
    reqMvPremType: string = "";

    // PARAMETER BASED REQUIRED FIELDS
    requiredBank: boolean = false;
    requiredLoanAccountNo: boolean = false;
    requiredAlfesNo: boolean = false;
    requiredPlateNo: boolean = false;
    requiredPurchaseValue: boolean = false;
    requiredSeats: boolean = false;
    requiredColor: boolean = false;
    requiredEngineNo: boolean = false;
    requiredChassisNo: boolean = false;
    requiredMcUsage: boolean = false;
    requiredMvFileNo: boolean = false;
    requiredMvType: boolean = false;
    requiredMvPremType: boolean = false;

    productInfo: any = {};
    includedVehicle: any[] = [];
    allowedFileExt: any[] = [];
    supportingDocsFiles: any[] = [];

    allowedFileSize: number = 0;
    bpiBankCd: number = 0;
    fmvValue: number = 0;

    nextLabel: string = "Next: ";
    productSublineCd: string = "";
    contractTypeCd!:number;
    imagePath: string = "";
    confirmDuplicateMessage: string = "";
    seatsErrorMessage: string = "";
    bankCaveat: string = "";
    supportDocErrorMsg: string = "";
    base64FileString: string = "";

    resetCoverage: boolean = false;
    acceptedToDraft: boolean = false;
    withDuplicateMessageProceed: boolean = false;
    withSupportingDocChange: boolean = false;
    displaySupportingDoc: boolean = false;

    showFmvUsed:boolean = this.parameterService.paramV('SHOW_FMV_FOR_USED')?.split(',')?.indexOf(this.userDetailsService.accountType || 'P')!=-1;
    editFmvUsed:boolean = this.parameterService.paramV('EDIT_FMV_FOR_USED')?.split(',')?.indexOf(this.userDetailsService.accountType || 'P')!=-1;
    genPageUser: any[] = this.parameterService.paramV('DISPLAY_GENERAL_PAGE')?.split(',') || [];

    @ViewChild("orcrOverlay") orcrOverlay!: OverlayComponent;
    @ViewChild("duplicateVehicleOverlay") duplicateVehicleOverlay!: OverlayComponent;
    @ViewChild('purchasePriceInput') purchasePriceInput!: ElementRef;

    includedCarCompanyCd!: any[];
    includedMakeCd!: any[];
    includeSeriesCd!: any[];

    dateToday = new Date();

    initFormValid: Boolean = true;

    get mvFileNo() {return this.form.get('mvFileNo')}

    constructor (
        // PUBLIC
        public formService: FormService,
        public userDetailsService: UserDetailsService,
        public autocompleteService: AutocompleteService,
        public parameterService: ParameterService,

        // PRIVATE
        private router: Router,
        private formBuilder: FormBuilder,
        private pageValidationService: PageValidationService,
        private productService: ProductService,
        private stepperDtlService: StepperDtlService,
        private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
        private dialogBoxService: DialogBoxService,
        private appMessageService: AppMessageService,
        private securityService: SecurityService,
        private downloadService: DownloadService,
        private popupService: PopupService,
        private fbAnalyticsService: FacebookEventAnalyticsService
    ) {
        this.pageValidationService.validatePageAccessibility();
        this.buildVehicleInfoInProductInfo();
        this.initializeStepperDetail();

        this.bankCaveat = parameterService.paramV('INSTRUCTIONS_BANK');

        // SET AUTO-COMPLETE INITIAL VALUES
        this.filteredBankDetailOptions = this.autocompleteService.set(this.form, "bankDetail", this.getBanks());
        this.filteredBeingUsedDetailOptions = this.autocompleteService.set(this.form, "beingUsedDetail", this.getVEUsages());
        // this.filteredMvTypeOptions = this.autocompleteService.set(this.form, "mvTypeDetail", this.getMvTypes());
        // this.filteredMvPremTypeOptions = this.autocompleteService.set(this.form, "mvPremTypeDetail", this.getMvPremTypes());

        if (!this.productInfo.vehicleInfo.mortgageType) {
            this.productInfo.vehicleInfo.mortgageType = "N";
        }

        if (!this.productInfo.vehicleInfo.vehicleType) {
            this.productInfo.vehicleInfo.vehicleType = "1";
        }

        for (let veUsage of this.jsonDataService.data.veUsages) {
            if (veUsage.veUsageCd === 1) {
                this.productInfo.vehicleInfo.beingUsedDetail = {
                    value: veUsage.veUsageCd,
                    text: veUsage.veUsageDesc
                }
            }
        }

        this.productSublineCd = this.productInfo.sublineCd;
        this.imagePath = this.parameterService.paramV("ORCR_IMAGE");
        this.bpiBankCd = this.parameterService.paramN("BPI_BANK_CD");
        this.allowedFileExt = this.parameterService.paramV("RISK_DTL_FILE_EXT").split(",");
        this.allowedFileSize = this.parameterService.paramN("RISK_DTL_FILE_SIZE");

        this.productService.setProductInfo2(this.productInfo);
        this.form.patchValue(this.productInfo.vehicleInfo);
        this.listAllIncludedVehicle();
        this.initializeRequiredFields();
        this.initializeVehicleMortgageDependents(this.form.controls["mortgageType"].value);
        this.initializeVehicleTypeDependent(this.form.controls["vehicleType"].value, false);
        this.initializeYearModelDependentsAutocomplete();
        this.initializeFormIfPolicy();
        this.supportingDocCheck();
        this.initFormValid = this.form.valid;
        setTimeout((e:any)=>
            popupService.popup.open('RD'), 0);
    }

    ngOnInit(): void {
        setInputFormatter();
        if (this.productInfo.creationProcess === "quotation") {
            if (this.productInfo.sublineCd == 'COM') this.fbAnalyticsService.trackEvent('Motor_Quotation_Vehicle_Details')
        } else if (this.productInfo.creationProcess === "policy") {
            if (this.productInfo.sublineCd == 'COM') {
                this.fbAnalyticsService.trackEvent('Motor_Epolicy_ Email')
            } else if (this.productInfo.sublineCd == 'CTPL') {
                this.fbAnalyticsService.trackEvent('Liability_EPolicy_Vehicle_Details')
            }
        }
    }

    buildVehicleInfoInProductInfo(): void {
        this.productService.productInfo.quoteStatCd ? "" : this.productService.setQuoteStat(1);

        this.productInfo = this.productService.productInfo;

        if (this.productInfo.vehicleInfo && ((this.productInfo.vehicleInfo.vehicleType == 2 && !this.showFmvUsed) || this.productInfo?.planInfo?.planCd === "CTPL")) {
            this.productInfo.vehicleInfo.purchasePrice = '';
        }

        const vehicleInfo = this.productInfo.vehicleInfo ? this.productInfo.vehicleInfo : {
            mortgageType: "",
            bankDetail: "",
            loanAccountNo: "",
            alfesNo: "",
            vehicleType: "",
            plateNo: "",
            yearModelDetail: "",
            makeDetail: "",
            modelDetail: "",
            subModelDetail: "",
            makeTypeCd: "",
            ccWt: "",
            gasType: "",
            gasDesc: "",
            noOfSeats: "",
            fmvMinValue: "",
            fmvMaxValue: "",
            seatsCovered: "",
            color: "",
            purchasePrice: "",
            engineNo: "",
            chassisNo: "",
            beingUsedDetail: "",
            mvFileNo: "",
            mvTypeDetail: "",
            mvPremTypeDetail: "",
            remarks: "",
            cocNo: "",
            cocAuthNo: "",
            cocAuthDate: "",
            genPageWord: ""
        };

        this.productInfo.vehicleInfo = vehicleInfo;
        this.productService.setProductInfo2(this.productInfo);
    }

    initializeStepperDetail(): void {
        this.stepperDtlService.childInstance = this;
        this.stepperDtlService.showStepperDtl = true;
        this.stepperDtlService.titleStepperDtl = "Vehicle Details";

        if (this.productInfo.creationProcess === "quotation") {
            this.stepperDtlService.stepperDtlStepNo = 2;
            this.nextLabel += "Coverage";
        } else if (this.productInfo.creationProcess === "policy") {
            this.stepperDtlService.stepperDtlStepNo = 1;
            this.nextLabel += "Customer Info";
        }

        this.stepperDtlService.stepperDtlBtns = [
            { class: "btn4", label: "Back", action: "move", actionParam: "back" },
            { class: "btn5", label: "Save", action: "save", isShow: this.userDetailsService.userId ? "true" : "false" },
            { class: "btn2", label: this.nextLabel, action: "move", actionParam: "next" }
        ];

        if (this.productInfo.creationProcess === "quotation") {
            if (this.productService.productInfo.sublineCd == 'COM') {
                // this.fbAnalyticsService.trackEvent('Motor_Quotation_Vehicle_Details')
            } 
        } else if (this.productInfo.creationProcess === "policy") {
            if (this.productService.productInfo.sublineCd == 'COM') {
                // this.fbAnalyticsService.trackEvent('Motor_Epolicy_ Customer_Info')
            }
        }
    }

    private listAllIncludedVehicle(): void {
        let makeTypeCds: any = "";
        let highRiskIds: any = "";

        const filter = this.jsonDataService.data.filterPerPlan.filter((plan:any)=>this.productInfo.lineCd==plan.lineCd && this.productSublineCd == plan.sublineCd && this.productInfo.planInfo.planCd == plan.planCd)[0];
        const filterMakeTypeCd = filter && filter.filterMakeTypeCd;
        const filterHighRiskId = filter && filter.filterHighRiskId;
        makeTypeCds = filterMakeTypeCd ? filterMakeTypeCd.split(",").map((a: any) => a.trim()) : "";
        highRiskIds =  filterHighRiskId ? filterHighRiskId.split(",").map((a: any) => Number(a.trim())) : "";

        for (let vehicle of this.jsonDataService.data.engineSeries) {
            if (this.productSublineCd === "CTPL" || vehicle.seats > 0) {
                if (makeTypeCds && highRiskIds) {
                    if (makeTypeCds.indexOf((vehicle.makeTypeCd || "").substr(0, 1)) >= 0 && highRiskIds.indexOf(vehicle.highRiskId) >= 0) {
                        this.includedVehicle.push({
                            carCompanyCd: vehicle.carCompanyCd,
                            makeCd: vehicle.makeCd,
                            seriesCd: vehicle.seriesCd
                        });
                    }
                } else if (makeTypeCds) {
                    if (makeTypeCds.indexOf((vehicle.makeTypeCd || "").substr(0, 1)) >= 0) {
                        this.includedVehicle.push({
                            carCompanyCd: vehicle.carCompanyCd,
                            makeCd: vehicle.makeCd,
                            seriesCd: vehicle.seriesCd
                        });
                    }
                } else if (highRiskIds) {
                    if (highRiskIds.indexOf(vehicle.highRiskId) >= 0) {
                        this.includedVehicle.push({
                            carCompanyCd: vehicle.carCompanyCd,
                            makeCd: vehicle.makeCd,
                            seriesCd: vehicle.seriesCd
                        });
                    }
                } else {
                    // this.includedVehicle.push({
                    //     carCompanyCd: vehicle.carCompanyCd,
                    //     makeCd: vehicle.makeCd,
                    //     seriesCd: vehicle.seriesCd
                    // });
                }
            }
        }

        this.includedCarCompanyCd = this.includedVehicle.map(ve => ve.carCompanyCd);
        this.includedMakeCd = this.includedVehicle.map(ve => ve.makeCd);
        this.includeSeriesCd = this.includedVehicle.map(ve => ve.seriesCd);
    }

    initializeRequiredFields(): void {
        this.reqMcBank = this.parameterService.paramV("REQ_MC_MORTACCT");
        this.reqMcLan = this.parameterService.paramV("REQ_MC_LAN");
        this.reqMcAlfesNo = this.parameterService.paramV("REQ_MC_ALFESNO");
        this.reqMcPlateNo = this.parameterService.paramV("REQ_MC_PLATENO");
        this.reqMcSeats = this.parameterService.paramV("REQ_MC_SEATS");
        this.reqMcColor = this.parameterService.paramV("REQ_MC_COLOR");
        this.reqMcEngine = this.parameterService.paramV("REQ_MC_ENGINE");
        this.reqMcChassis = this.parameterService.paramV("REQ_MC_CHASSIS");
        this.reqMcUsage = this.parameterService.paramV("REQ_MC_USAGE");

        if (this.productSublineCd === "CTPL") {

            this.form.get('vehicleType')?.valueChanges.subscribe((e) => {
                this.toggleMvPremRequired();
            });
            this.toggleMvPremRequired();

            this.reqMvType = this.parameterService.paramV("REQ_MC_MVTYPE");
            this.reqMvPremType = this.parameterService.paramV("REQ_MC_MVPREMTYPE");
        }

        Object.keys(this.form.controls).forEach(field => {
            this.setFieldRequired(field);
        });
    }

    toggleMvPremRequired() {
        let newReqMvFileNo = this.parameterService.paramV(this.form.get('vehicleType')?.value == 1 ? "REQ_MC_MVFILENO_NEW" : 'REQ_MC_MVFILENO_USED');
        if (newReqMvFileNo != this.reqMvFileNo) {
            this.reqMvFileNo = newReqMvFileNo;
            this.setFieldRequired('mvFileNo');
        }
    }

    checkMvFileNoLength():boolean {
        if (this.form.controls["mvFileNo"].value) {
            if (this.form.controls["mvFileNo"].value.length !== this.parameterService.paramN("MVFILENO_LENGTH")) {
                this.form.controls["mvFileNo"].setErrors({"incorrect" : true});
                return true;
            }else{
                this.form.controls["mvFileNo"].setErrors(null);
                return false;
            }
        }
        return false;
    }

    setFieldRequired(field: string): void {
        if (field === "bankDetail" && this.willRequireTheField(this.reqMcBank)) {
            this.form.controls[field].setValidators([Validators.required]);
            if (this.showVehicleMortgageDependents) {
                this.requiredBank = true;
                this.form.controls[field].setValidators([Validators.required]);
            } else {
                this.requiredBank = false;
                this.form.controls[field].clearValidators();
            }
        } else if (field === "loanAccountNo" && this.willRequireTheField(this.reqMcLan)) {
            if (this.showVehicleMortgageDependents) {
                this.requiredLoanAccountNo = true;
                this.form.controls[field].setValidators([Validators.required]);
            } else {
                this.requiredLoanAccountNo = false;
                this.form.controls[field].clearValidators();
            }
        } else if (field === "alfesNo" && this.willRequireTheField(this.reqMcAlfesNo)) {
            if (this.showBankNameDependent) {
                this.requiredAlfesNo = true;
                this.form.controls[field].setValidators([Validators.required]);
            } else {
                this.requiredAlfesNo = false;
                this.form.controls[field].clearValidators();
            }
        } else if (field === "plateNo" && this.willRequireTheField(this.reqMcPlateNo)) {
            this.requiredPlateNo = true;
            this.form.controls[field].setValidators([Validators.required]);

            const nonRequiredPlateNo = this.jsonDataService.data.nonRequiredPlateNo;
            if(this.productInfo.sublineCd == 'CTPL' && this.form.controls["vehicleType"].value == "2"){
                // do not remove required
            }else if (nonRequiredPlateNo && this.form.controls["vehicleType"].value === "1") {
                for (const nonRequiredPlateNoDtl of nonRequiredPlateNo) {
                    if (   nonRequiredPlateNoDtl.lineCd === this.productInfo.lineCd
                        && nonRequiredPlateNoDtl.sublineCd === this.productInfo.sublineCd
                        && nonRequiredPlateNoDtl.planCd === this.productInfo.planInfo.planCd) {
                            this.requiredPlateNo = false;
                            this.form.controls[field].clearValidators();
                    }
                }
            }
        } else if (field === "purchasePrice") {
            if ((this.showVehicleTypeDependent || (!this.showVehicleTypeDependent && this.showFmvUsed)) && this.productSublineCd !== "CTPL") {
                this.requiredPurchaseValue = true;
                this.form.controls[field].setValidators([Validators.required]);
            } else {
                this.requiredPurchaseValue = false;
                this.form.controls[field].clearValidators();
            }
        } else if (field === "seatsCovered" && this.willRequireTheField(this.reqMcSeats)) {
            this.requiredSeats = true;
            this.form.controls[field].setValidators([Validators.required]);
        } else if (field === "color" && this.willRequireTheField(this.reqMcColor)) {
            this.requiredColor = true;
            this.form.controls[field].setValidators([Validators.required]);
        } else if (field === "engineNo" && this.willRequireTheField(this.reqMcEngine)) {
            this.requiredEngineNo = true;
            this.form.controls[field].setValidators([Validators.required]);
        } else if (field === "chassisNo" && this.willRequireTheField(this.reqMcChassis)) {
            this.requiredChassisNo = true;
            this.form.controls[field].setValidators([Validators.required]);
        } else if (field === "beingUsedDetail" && this.willRequireTheField(this.reqMcUsage)) {
            this.requiredMcUsage = true;
            this.form.controls[field].setValidators([this.formService.autocomplete(), Validators.required]);
        } else if (this.productSublineCd === "CTPL") {
            if (field === "mvFileNo") {
                if (this.form.get('vehicleType')?.value != 1) {
                    if (this.willRequireTheField(this.reqMvFileNo)) {
                        this.requiredMvFileNo = true;
                        this.form.controls[field].setValidators([Validators.required]);
                    } else {
                        this.requiredMvFileNo = false;
                        this.form.controls[field].clearValidators();
                    }
                } else {
                    this.requiredMvFileNo = false;
                    this.form.controls[field].clearValidators();
                }
            } else if (field === "mvTypeDetail" && this.willRequireTheField(this.reqMvType)) {
                this.requiredMvType = true;
                this.form.controls[field].setValidators([this.formService.autocomplete(), Validators.required]);
            } else if (field === "mvPremTypeDetail" && this.willRequireTheField(this.reqMvPremType)) {
                this.requiredMvPremType = true;
                this.form.controls[field].setValidators([this.formService.autocomplete(), Validators.required]);
            }else if (field === 'plateNo' ){
                if(this.form.controls["vehicleType"].value == "2"){
                    this.requiredPlateNo = true;
                    this.form.controls[field].setValidators([Validators.required]);
                }else{
                    this.requiredPlateNo = false;
                    this.form.controls[field].clearValidators();
                }
                
            }
        }

        this.form.controls[field].updateValueAndValidity();
    }

    willRequireTheField(param: string): boolean {
        return param === "Q" || (param === "P" && this.productInfo.creationProcess === "policy");
    }

    initializeFormIfPolicy(): void {
        if (this.productInfo.creationProcess === "policy") {
            this.form.controls["vehicleType"].disable();
            this.form.controls["yearModelDetail"].disable();
            this.form.controls["makeDetail"].disable();
            this.form.controls["modelDetail"].disable();
            this.form.controls["subModelDetail"].disable();
            this.form.controls["purchasePrice"].disable();
            this.form.controls["seatsCovered"].disable();
            if(this.form.controls["vehicleType"].value == "2" && this.productInfo.sublineCd === 'CTPL'){
                this.form.controls["plateNo"].disable();
            }
        }
    }

    initializeVehicleMortgageDependents(mortgageType: string): void {
        if (mortgageType === "Y") {
            this.showVehicleMortgageDependents = true;
            this.form.controls["bankDetail"].setValidators([this.formService.autocomplete()]);

            const vehicleInfo = this.form.controls["bankDetail"].value || {};
            if (Object.keys(vehicleInfo).length > 0) {
                if (vehicleInfo.alfesTag === 'Y') {
                    this.showBankNameDependent = true;
                }
            } else {
                // this.setInitialBank();
            }
        } else {
            this.showVehicleMortgageDependents = false;
            this.showBankNameDependent = false;
            this.form.controls["bankDetail"].clearValidators();
            this.form.controls["bankDetail"].setValue("");
            this.form.controls["loanAccountNo"].setValue("");
            this.form.controls["alfesNo"].setValue("");
        }

        this.form.controls["bankDetail"].updateValueAndValidity();
        this.setFieldRequired("bankDetail");
        this.setFieldRequired("loanAccountNo");
        this.setFieldRequired("alfesNo");
    }

    initializeVehicleTypeDependent(vehicleType: string, resetValue: boolean): void {
        console.log("vehicleType",vehicleType)
        this.showVehicleTypeDependent = vehicleType === "1";
        this.setYearModelOptions();

        if (this.productSublineCd !== "CTPL" && this.form.get("subModelDetail")?.value?.value) {
            this.fmvValue = this.getFMVValue();
            console.log("fmvValue");
            if(vehicleType == '2' && !this.editFmvUsed && this.showFmvUsed && resetValue){
                this.form.controls["purchasePrice"].setValue(this.fmvValue);
                this.form.controls["purchasePrice"].enable();
                setTimeout(() => {
                    this.purchasePriceInput?.nativeElement.focus();
                    this.purchasePriceInput?.nativeElement.blur();
                    this.disablePurchasePriceFromParam(vehicleType);
                }, 0)
            }else if(vehicleType == '2' && !this.showFmvUsed && resetValue){
                this.form.controls["purchasePrice"].setValue('');
                this.disablePurchasePriceFromParam(vehicleType);
            }else{
                this.disablePurchasePriceFromParam(vehicleType);
            }
        }
        
        this.setFieldRequired("plateNo");
        this.setFieldRequired("purchasePrice");

        if (this.productSublineCd === "CTPL") {
            for (const submodel of this.getSubModels()) {
                if (submodel.text === this.form.controls["subModelDetail"].value.text) {
                    this.contractTypeCd = submodel.contractTypeCd;
                    let mvTypes = this.getMvTypes();
                    if (mvTypes.map(a => a.value).indexOf(this.form.get("mvTypeDetail")?.value?.value) < 0) {
                        this.form.controls["mvTypeDetail"].setValue(mvTypes[0] || "");
                    }
                    this.filteredMvTypeOptions = this.autocompleteService.set(this.form, "mvTypeDetail", mvTypes);
                    break;
                }
            }

            let mvPremTypes:any[] = this.getMvPremTypes();
            if(mvPremTypes.map(a=>a.value).indexOf(this.form.get("mvPremTypeDetail")?.value?.value) == -1){
                this.form.controls["mvPremTypeDetail"].setValue(mvPremTypes[0]||"");
            }
            this.filteredMvPremTypeOptions = this.autocompleteService.set(this.form, "mvPremTypeDetail",mvPremTypes );
            if (this.form.get('vehicleType')?.value == 1) {
                this.form.controls["mvFileNo"].setValue("")
            }
        }
    }

    disablePurchasePriceFromParam(vehicleType:string){
        if((vehicleType == '1' || (vehicleType == '2' && this.editFmvUsed)) &&
            this.form.get("subModelDetail")?.value?.value != undefined
        ){
            this.form.controls["purchasePrice"].enable();
        }else{
            this.form.controls["purchasePrice"].disable();
        }
    }

    setInitialBank(): void {
        for (let bank of this.jsonDataService.data.banks) {
            if (this.userDetailsService.accountType === "E" && bank.bankCd === this.bpiBankCd) {
                this.form.controls["bankDetail"].setValue({
                    value: bank.bankCd,
                    text: bank.bankName
                });
                this.showBankNameDependent = true;
            }
        }
    }

    resetBankDependent(): void {
        this.showBankNameDependent = false;
        this.form.controls["alfesNo"].setValue("");
    }

    move(action: string): void {
        if (this.userDetailsService.userId) {
            const savedVehicleInfo = JSON.parse(this.jsonDataService.retrieveFromStorage("productInfo")).vehicleInfo;

            if (!savedVehicleInfo.yearModelDetail) {
                if (action === "back") {
                    if (this.form.dirty) {
                        this.showConfirm(action);
                    } else {
                        this.proceedMove(action);
                    }
                } else {
                    this.appMessageService.showAppMessage("Please fill and save the vehicle details form first.", "info");
                }
            } else  {
                if (action === "next") {
                    if (this.form.valid) {
                        if(!this.initFormValid && this.form.dirty){
                            this.appMessageService.showAppMessage("Please save vehicle details.", "error")
                        }
                        else if (this.checkIfThereAreChanges()) {
                            this.showConfirm(action);
                        }else{
                            this.proceedMove(action);
                        }
                    } else {
                        this.appMessageService.showAppMessage("There are missing or invalid information.", "info");
                    }
                } else {
                    this.proceedMove(action);
                }
            }
        } else {
            if (action === "next") {
                if (this.form.valid) {
                    this.checkCoverageDependent(action);
                } else {
                    this.appMessageService.showAppMessage("There are missing or invalid information.", "info");
                }
            } else {
                this.checkCoverageDependent(action);
            }
        }
    }

    checkIfThereAreChanges(): boolean {
        const savedVehicleInfo = JSON.parse(this.jsonDataService.retrieveFromStorage("productInfo")).vehicleInfo;

        let changeTag = false;
        Object.keys(this.form.controls).forEach(field => {
            if (typeof this.form.controls[field].value === "object") {
                Object.keys(this.form.controls[field].value).forEach(fieldValue => {
                    const value = this.form.controls[field].value[fieldValue];
                    if (typeof value === "object" && value) {
                        Object.keys(this.form.controls[field].value[fieldValue]).forEach(fieldValue2 => {
                            if (this.form.controls[field].value[fieldValue][fieldValue2] !== savedVehicleInfo[field][fieldValue][fieldValue2]) {
                                changeTag = true;
                            }
                        });
                    } else if (value !== savedVehicleInfo[field][fieldValue]) {
                        changeTag = true;
                    }
                });
            } else if (this.form.controls[field].value !== savedVehicleInfo[field]) {
                changeTag = true;
            }
        });

        return changeTag;
    }

    showConfirm(action: string): void {
        this.dialogBoxService.open({
            messageType: "C",
            content: "You have unsaved changes. Do you want to continue?",
            buttons: [
                { text: "No" },
                {
                    text: "Yes", action: () => {
                        this.proceedMove(action);
                    }
                }
            ]
        });
    }

    save(): void {
        if (this.form.dirty) {
            if (this.form.valid) {
                const form = this.form.getRawValue();
                if (form.plateNo === "" && form.engineNo === ""
                    && form.chassisNo === "" && form.mvFileNo === "") {
                        this.checkCoverageDependent("");
                } else {
                    this.jsonDataService.contorlLoading(true);
                    this.apiCallService.chkBlockedVehicle({
                        plateNo: form.plateNo,
                        engineNo: form.engineNo,
                        chassisNo: form.chassisNo,
                        mvFileNo: form.mvFileNo
                    }).subscribe((response:any) => {
                        this.securityService.checkRequestKeyResponse(response, () => {
                            if (response.status === "SUCCESS") {
                                this.securityService.hasValidCsrfToken(response, () => {
                                    this.jsonDataService.contorlLoading(false);
                                    const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                                    if (res.plateNo === 0 && res.engineNo === 0 && res.chassisNo === 0 && res.mvFileNo === 0){
                                        this.checkCoverageDependent("");
                                    } else {
                                        this.blockedVehicleMessage(res);        
                                    }
                                });
                            } else {
                                this.jsonDataService.contorlLoading(false);
                                this.appMessageService.showAppMessage("There's something wrong saving your vehicle details. Please try again later.", "error");
                            }
                        });
                    });
                }
            } else {
                this.appMessageService.showAppMessage("There are missing or invalid information.", "info");
            }
        } else {
            this.appMessageService.showAppMessage("You don't have any changes to save.", "info");
        }
    }

    blockedVehicleMessage(res: any): void {
        let form = this.form.getRawValue();
        let message = "";
        let blocked = "";
        
        if (res.plateNo && res.engineNo && res.chassisNo && res.mvFileNo) {
            blocked = "Plate No, Engine No, Chassis No and MV File No";
        } else {
            if (res.plateNo) {
                blocked = "Plate No";
            }
            if (res.engineNo) {
                blocked = "Engine No";
            }
            if (res.chassisNo) {
                blocked = "Chassis No";
            }
            if (res.mvFileNo) {
                blocked = "MV File No";
            }
            if (res.plateNo && res.engineNo) {
                blocked = "Plate No and Engine No";
            }
            if (res.plateNo && res.chassisNo) {
                blocked = "Plate No and Chassis No";
            }
            if (res.plateNo && res.mvFileNo) {
                blocked = "Plate No and MV File No";
            }
            if (res.engineNo && res.chassisNo) {
                blocked = "Engine No and Chassis No";
            }
            if (res.engineNo && res.mvFileNo) {
                blocked = "Engine No and MV File No";
            }
            if (res.chassisNo && res.mvFileNo) {
                blocked = "Chassis No and MV File No";
            }
            if (res.plateNo && res.engineNo && res.chassisNo) {
                blocked = "Plate No, Engine No and Chassis No"
            }
            if (res.plateNo && res.engineNo && res.mvFileNo) {
                blocked = "Plate No, Engine No and MV File No"
            }
            if (res.plateNo && res.chassisNo && res.mvFileNo) {
                blocked = "Plate No, Chassis No and MV File No"
            }
            if (res.engineNo && res.chassisNo && res.mvFileNo) {
                blocked = "Engine No, Chassis No and MV File No"
            }
        }
        this.dialogBoxService.open({
            messageType: "C",
            content: message.concat("The ", blocked,  " you have entered is subject for review and approval. You may contact BPI MS for more information."),
            buttons: [{
                text: "Contact BPI MS", action: () => {
                    const url = this.router.serializeUrl(
                        this.router.createUrlTree([`/contact-us`], {
                            queryParams: {
                                brand: form.makeDetail.text,
                                yearModel: form.yearModelDetail.value,
                                model: form.modelDetail.text + ' ' + form.subModelDetail.text,
                                srp: form.purchasePrice
                            }
                        }
                        )
                    );
                    this.router.navigateByUrl(url);
                }
            }, {
                text: "Close"
            }]
        });
    }

    checkCoverageDependent(action: string): void {
        const coverage = this.productInfo.coverageInfo;
        
        if(this.form.get('plateNo')?.dirty || this.form.get('vehicleType')?.dirty){
            this.productService.productInfo.reEffDateFlag = true;
        }
        
        if (coverage === undefined || Object.keys(coverage).length === 0 || coverage.tempId === null) {
            this.resetCoverage = false;
            action ? this.processMove(action) : this.processSave();
        } else {
            if (this.willResetCoverage()) {
                let content = this.productService.productInfo.quoteStatCd == 3 ? 'There are changes made in Year Model, Brand, Model, Variant and/or Seats Covered which will require re-computing your coverage and change the status of your quotation back to draft. Do you want to proceed?' :
                    'There are changes made in Year Model, Make, Model, Sub-model and/or Seats Covered which will require re-computing your coverage. Do you want to proceed?';

                this.dialogBoxService.open({
                    messageType: "C",
                    content: content,
                    buttons: [
                        { text: "No" },
                        {
                            text: "Yes", action: () => {
                                this.resetCoverage = true;
                                this.acceptedToDraft = this.productService.productInfo.quoteStatCd == 3;
                                if (action) {
                                    this.processMove(action);
                                } else {
                                    this.processSave();
                                }
                            }
                        }
                    ]
                });
            } else {
                this.resetCoverage = false;
                action ? this.processMove(action) : this.processSave();
            }
        }
    }

    processMove(action: string): void {
        const { cocNo, cocAuthNo, cocAuthDate } = this.productInfo.vehicleInfo;
        this.productInfo.vehicleInfo = this.form.getRawValue();
        this.productInfo.vehicleInfo.cocNo = cocNo;
        this.productInfo.vehicleInfo.cocAuthNo = cocAuthNo;
        this.productInfo.vehicleInfo.cocAuthDate = cocAuthDate;
        // this.productService.setContractType(this.getVehicleContractTypeCd());
        this.productService.setProductInfo2(this.productInfo);
        this.resetCoverage ? this.resetCoverageFunc() : "";
        this.proceedMove(action);
    }

    proceedMove(action: string): void {
        if (this.productInfo.creationProcess === "quotation") {
            if (action === "next") {
                this.router.navigate(["/quotation/coverage-perils"]);
            } else {
                this.router.navigate(["/quotation/choose-plan"]);
            }
        } else if (this.productInfo.creationProcess === "policy") {
            if (action === "next") {
                this.router.navigate(["/policy/customer-information"]);
            } else {
                this.router.navigate(["/dashboard"]);
            }
        }
    }

    processSave(): void {
        this.checkDuplicateVehicleDetails();
    }

    willResetCoverage(): boolean {
        const currentVehicleDetail = this.form.getRawValue();
        const lastVehicleDetail = JSON.parse(this.jsonDataService.retrieveFromStorage("productInfo")).vehicleInfo;

        if (lastVehicleDetail !== undefined
            && (currentVehicleDetail.yearModelDetail.value !== lastVehicleDetail.yearModelDetail.value
                || currentVehicleDetail.makeDetail.value !== lastVehicleDetail.makeDetail.value
                || currentVehicleDetail.modelDetail.value !== lastVehicleDetail.modelDetail.value
                || currentVehicleDetail.subModelDetail.value !== lastVehicleDetail.subModelDetail.value
                || currentVehicleDetail.seatsCovered !== lastVehicleDetail.seatsCovered
                || currentVehicleDetail.purchasePrice !== lastVehicleDetail.purchasePrice
                || currentVehicleDetail.vehicleType !== lastVehicleDetail.vehicleType)
        ) {
            return true;
        } else {
            return false;
        }
    }

    resetCoverageFunc(): void {
        this.productInfo.perilInfo = [];
        this.productInfo.coverages = [];
        this.productInfo.ara = [];
        this.productInfo.peril = [];
        this.productInfo.taxes = [];
        this.productInfo.coverageInfo = {};
        this.productInfo.recomputeFlag = true;
        this.productService.setProductInfo2(this.productInfo);
    }

    checkDuplicateVehicleDetails(): void {
        const form = this.form.getRawValue();
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.checkDuplicateVehicleDetails({
            quoteId: this.productInfo.quoteId,
            lineCd: this.productInfo.lineCd,
            sublineCd: this.productInfo.sublineCd,
            plateNo: form.plateNo,
            engineNo: form.engineNo,
            chassisNo: form.chassisNo
        }).subscribe((response:any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                        const { quotePlateNoExists, quoteEngineNoExists, quoteChassisNoExists } = res;
                        const { policyPlateNoExists, policyEngineNoExists, policyChassisNoExists } = res;

                        let duplicateNos = "";
                        this.confirmDuplicateMessage = "";

                        if (policyPlateNoExists) {
                            duplicateNos = "Plate No. has";
                        }
                        if (policyEngineNoExists) {
                            duplicateNos = "Engine No. has";
                        }
                        if (policyChassisNoExists) {
                            duplicateNos = "Chassis No. has";
                        }
                        if (policyPlateNoExists && policyEngineNoExists) {
                            duplicateNos = "Plate No. and Engine No. have";
                        }
                        if (policyPlateNoExists && policyChassisNoExists) {
                            duplicateNos = "Plate No. and Chassis No. have";
                        }
                        if (policyEngineNoExists && policyChassisNoExists) {
                            duplicateNos = "Engine No. and Chassis No. have";
                        }
                        if (policyPlateNoExists && policyEngineNoExists && policyChassisNoExists) {
                            duplicateNos = "Plate No., Engine No., and Chassis No. have";
                        }

                        if (duplicateNos) {
                            this.withDuplicateMessageProceed = false;
                            this.confirmDuplicateMessage = `Unable to save your vehicle details. Your ${duplicateNos} already been processed to policy. Please contact BPI MS for more information.`;
                        }

                        if (!this.confirmDuplicateMessage) {
                            duplicateNos = "";
                            if (quotePlateNoExists) {
                                duplicateNos = "Plate No.";
                            }
                            if (quoteEngineNoExists) {
                                duplicateNos = "Engine No.";
                            }
                            if (quoteChassisNoExists) {
                                duplicateNos = "Chassis No.";
                            }
                            if (quotePlateNoExists && quoteEngineNoExists) {
                                duplicateNos = "Plate No. and Engine No.";
                            }
                            if (quotePlateNoExists && quoteChassisNoExists) {
                                duplicateNos = "Plate No. and Chassis No.";
                            }
                            if (quoteEngineNoExists && quoteChassisNoExists) {
                                duplicateNos = "Engine No. and Chassis No.";
                            }
                            if (quotePlateNoExists && quoteEngineNoExists && quoteChassisNoExists) {
                                duplicateNos = "Plate No., Engine No., and Chassis No.";
                            }

                            if (duplicateNos) {
                                this.withDuplicateMessageProceed = true;
                                this.confirmDuplicateMessage = `A quotation has been requested for the ${duplicateNos} You may contact BPI MS for more information or click Save to proceed.`;
                            }
                        }

                        if (this.confirmDuplicateMessage) {
                            this.jsonDataService.contorlLoading(false);
                            this.duplicateVehicleOverlay.open();
                        } else {
                            this.saveVehicleDetails();
                        }
                    });
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage("Unable to save your vehicle details. Please contact BPI MS for more information.", "error");
                }
            });
        });
    }

    saveVehicleDetails(): void {
        const form = this.form.getRawValue();
        const fmvValue = this.getFMVValue();
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.saveVehicleDetails({
            quoteId: this.productInfo.quoteId,
            pvCd: this.productInfo.prodVouchInfo?.prodVouchCd,
            lineCd: this.productInfo.lineCd,
            sublineCd: this.productInfo.sublineCd,
            planCd: this.productInfo.planInfo.planCd,
            mortAccTag: form.mortgageType,
            bankCd: form.bankDetail.value,
            lanNo: form.loanAccountNo,
            alfesNo: form.alfesNo,
            veTypeTag: form.vehicleType,
            plateNo: form.plateNo,
            modelYear: form.yearModelDetail.value,
            carCompanyCd: form.makeDetail.value,
            makeCd: form.modelDetail.value,
            seriesCd: form.subModelDetail.value,
            purchasePrice: form.purchasePrice,
            color: form.color,
            seatsCovered: form.seatsCovered,
            engineNo: form.engineNo,
            chassisNo: form.chassisNo,
            mvFileNo: form.mvFileNo,
            mvType: form.mvTypeDetail.value,
            mvPremType: form.mvPremTypeDetail.value,
            veUsageCd: form.beingUsedDetail.value,
            remarks: form.remarks,
            acceptedToDraft: this.acceptedToDraft,
            creationProcess: this.productService.productInfo.creationProcess,
            supportingDocs: form.supportingDocs,
            genPageWord: form.genPageWord,
            fmvValue: fmvValue
        }).subscribe((response:any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                if (response.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(response, () => {
                        if (this.supportingDocsFiles.length > 0 && this.withSupportingDocChange) {
                            this.uploadSupportingDocs(0, response);
                        } else {
                            this.proceedAfterSave(response);
                        }
                    });
                } else {
                    this.jsonDataService.contorlLoading(false);
                    const siLimit = this.getSILimit();
                    const purchasePrice = this.form.controls["purchasePrice"].value;
                    if (purchasePrice && siLimit?.siAmtLimit && purchasePrice > siLimit?.siAmtLimit) {
                        this.dialogBoxService.open({
                            messageType: "I",
                            content: "Vehicle value is subject for review and approval. Please contact BPI MS for more information.",
                            buttons: [{
                                text: "Contact BPI MS", action: () => {
                                    const url = this.router.serializeUrl(
                                        this.router.createUrlTree([`/contact-us`], {
                                            queryParams: {
                                                brand: form.makeDetail.text,
                                                yearModel: form.yearModelDetail.value,
                                                model: form.modelDetail.text + ' ' + form.subModelDetail.text,
                                                srp: form.purchasePrice
                                            }
                                        }
                                        )
                                    );
                                    this.router.navigateByUrl(url);
                                }
                            }, {
                                text: "Close"
                            }]
                        });
                    } else {
                        this.appMessageService.showAppMessage("There's something wrong saving your vehicle details. Please try again later.", "error");
                    }
                }
            });
        });
    }

    async uploadSupportingDocs(index: number, response: any): Promise<void> {
        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
        this.jsonDataService.contorlLoading(true);
        const base64String = await this.formService.getFileAsBase64(this.supportingDocsFiles[index]);
        this.apiCallService.getPresignedUrl({
            action: "putObject",
            directory: `supporting-docs/vehicle-details/${res.quoteId}/${res.itemNo}`,
            filename: this.supportingDocsFiles[index].name,
            validation: "VehicleSuppDoc",
            base64FileString: base64String
        }).subscribe((getPresignedUrlResponse: any) => {
            this.securityService.checkRequestKeyResponse(getPresignedUrlResponse, () => {
                this.jsonDataService.contorlLoading(false);
                if (getPresignedUrlResponse.status === "SUCCESS") {
                    this.securityService.hasValidCsrfToken(getPresignedUrlResponse, () => {
                        const res = JSON.parse(this.jsonDataService.decrypt(getPresignedUrlResponse.response));
                        if (res.presignedUrl) {
                            this.apiCallService.uploadPresignedUrl(
                                res.presignedUrl, this.supportingDocsFiles[index]
                            ).subscribe((uploadPresignedUrlResponse: any) => {
                                if (index === (this.supportingDocsFiles.length-1)) {
                                    this.proceedAfterSave(response);
                                } else {
                                    this.uploadSupportingDocs(index+1, response);
                                }
                            });
                        } else {
                            this.jsonDataService.contorlLoading(false);
                            this.supportingDocsFiles = [];
                            this.form.controls["supportingDocs"].setValue([]);
                            this.appMessageService.showAppMessage("There's something wrong saving your supporting documents. All uploaded files are deleted. Please try again later.", "error");
                        }
                    });
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage("There's something wrong saving your vehicle details. Please try again later.", "error");
                }
            });
        });
    }

    proceedAfterSave(response: any): void {
        this.jsonDataService.contorlLoading(false);
        this.withSupportingDocChange = false;
        const res = JSON.parse(this.jsonDataService.decrypt(response.response));
        sessionStorage.setItem("rid", res.recordId);

        this.productInfo.quoteId = res.quoteId;
        const { cocNo, cocAuthNo, cocAuthDate } = this.productInfo.vehicleInfo;
        this.productInfo.vehicleInfo = this.form.getRawValue();
        this.productInfo.vehicleInfo.cocNo = cocNo;
        this.productInfo.vehicleInfo.cocAuthNo = cocAuthNo;
        this.productInfo.vehicleInfo.cocAuthDate = cocAuthDate;
        this.productInfo.policyHeaderDetails = res.headerDetails;

        if (this.acceptedToDraft) {
            this.productInfo.quoteStatCd = 1;
            this.acceptedToDraft = false;
        }
        this.productService.setProductInfo2(this.productInfo);
        if(this.form.get('plateNo')?.dirty){
            this.productService.productInfo.reEffDateFlag = true;
        }
        this.form.markAsPristine();
        this.resetCoverage ? this.resetCoverageFunc() : "";
        this.appMessageService.showAppMessage("Successfully saved.", "success");
    }

    getVehicleContractTypeCd(): number {
        return this.jsonDataService.data.engineSeries.filter((a: any) =>
            a.carCompanyCd === this.productInfo.vehicleInfo.makeDetail.value
            && a.makeCd === this.productInfo.vehicleInfo.modelDetail.value
            && a.seriesCd === this.productInfo.vehicleInfo.subModelDetail.value
        )[0]?.contractTypeCd || 1;
    }

    setPurchasePricePlaceholder(): string {
        if (typeof this.form.controls["subModelDetail"].value !== "object"
            || this.formService.isInvalid(this.form, "subModelDetail")) {
            return "Please select Variant";
        } else {
            return this.showVehicleTypeDependent ? 'Purchase Price' : 'Fair Market Value';
        }
    }

    validatePurchasePrice(): boolean {
        let response = false;
        if (this.form.controls["purchasePrice"].value) {
            const purchasePrice = this.form.controls["purchasePrice"].value;
            const siLimit = this.getSILimit();
            if (siLimit) {
                const fmv = this.fmvValue || this.getFMVValue();
                const min = fmv - (fmv * (siLimit.siMinPct / 100));
                const max = fmv + (fmv * (siLimit.siMaxPct / 100));
                if (purchasePrice) {
                    if ((siLimit.siMinPct && purchasePrice < min) || (siLimit.siMaxPct && purchasePrice > max)) {
                        response = true;
                    }

                    if (response) {
                        this.form.controls["purchasePrice"].setErrors({ "incorrect": true });
                        this.form.controls["purchasePrice"].markAsTouched();
                    }
                } else {
                    this.form.controls["purchasePrice"].setErrors(null);
                }
            }
        }
        return response;
    }

    getSILimit(): any {
        const accountType = this.userDetailsService.accountType;
        const userType = accountType ? accountType : "P";
        return this.jsonDataService.data.siLimit.filter((a: any) =>
            a.userType === userType
            && a.userLevel === (this.userDetailsService.userLevel || '0')
            && a.itemTypeCd === Number(this.form.controls["vehicleType"].value)
            && a.lineCd === this.productInfo.lineCd
        )[0];
    }

    validateSeatsCovered(): boolean {
        const noOfSeats = this.form.controls["noOfSeats"].value;
        const seatsCovered = this.form.controls["seatsCovered"].value;
        const showError = seatsCovered > noOfSeats;

        this.seatsErrorMessage = "Seats covered should be less than or equal to " + noOfSeats + ".";
        if (showError) {
            this.form.controls["seatsCovered"].setErrors({ "incorrect": true });
            this.form.controls["seatsCovered"].markAsTouched();
        } else {
            this.form.controls["seatsCovered"].setErrors(null);
        }

        return showError;
    }

    onFileChange(event: any): void {
        this.withSupportingDocChange = true;

        let totalSize = 0;
        this.form.controls["supportingDocs"].value.forEach((el: any) => {
            totalSize += (el.size / 1024 / 1024);
        });

        if (event.target.files && event.target.files.length) {
            const files = event.target.files;

            for (let index = 0; index < files.length; index++) {
                const reader = new FileReader();
                const element = files[index];

                reader.readAsDataURL(element);
                reader.onload = () => {
                    this.supportDocErrorMsg = "";
                    const type = mimeTypes.getExtension(element.type) || "";

                    if (!this.allowedFileExt.includes(type.toUpperCase())) {
                        this.supportDocErrorMsg = "Some files were not attached. Accepted file types: " + this.allowedFileExt.join(", ");
                    } else {
                        totalSize += (element.size / 1024 / 1024);

                        if (totalSize > this.allowedFileSize) {
                            this.supportDocErrorMsg = "You have reached the maximum file size for the attachment. Some files were not uploaded.";
                        } else {
                            const supportingDocs = this.form.controls["supportingDocs"].value;
                            supportingDocs.push({
                                filename: element.name,
                                size: element.size
                            });

                            this.supportingDocsFiles.push(element);
                            this.form.controls["supportingDocs"].setValue(supportingDocs);
                            this.form.markAsDirty();
                        }
                    }

                    if (this.supportDocErrorMsg) {
                        setTimeout(() => {
                            this.supportDocErrorMsg = "";
                        }, 5000);
                    }
                };
            }
        }
    }

    onClickView(index: number): void {
        const filename = this.form.controls["supportingDocs"].value[index].filename;

        let newFile = false;
        for (const supportingDocsFile of this.supportingDocsFiles) {
            if (supportingDocsFile.name === filename) {
                newFile = true;
                this.downloadService.downloadFile(supportingDocsFile);
            }
        }

        if (!newFile) {
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.getPresignedUrl({
                action: "getObject",
                directory: `supporting-docs/vehicle-details/${this.productInfo.quoteId}/1`,
                filename: filename
            }).subscribe((response: any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    this.jsonDataService.contorlLoading(false);
                    if (response.status === "SUCCESS") {
                        this.securityService.hasValidCsrfToken(response, () => {
                            const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                            this.downloadService.downloadFileUsingPresignedUrl(res.presignedUrl, filename);
                        });
                    }
                });
            });
        }
    }

    onClickDelete(index: number): void {
        this.form.controls["supportingDocs"].setValue(
            this.form.controls["supportingDocs"].value.filter((a:any, i:number) => { return i !== index })
        );
        this.supportingDocsFiles.splice(index, 1);
        this.form.markAsDirty();
        this.withSupportingDocChange = true;
    }

    backToQuotationProcessing(): void {
        this.productInfo.creationProcess = "quotation";
        this.productInfo.previousProcess = "quotation";
        this.productService.setProductInfo2(this.productInfo);
        this.router.navigate(["/quotation/choose-plan"]);
    }

    reprintQuote(): void {
        this.productInfo.creationProcess = "quotation";
        this.productInfo.previousProcess = "policy";
        this.productService.setProductInfo2(this.productInfo);
        this.router.navigate(["/quotation/email-print"]);
    }

    /*******************************************************************************/
    /*                                                                             */
    /*                           AUTO-COMPLETE FUNCTIONS                           */
    /*                                                                             */
    /*******************************************************************************/

    /* BANK AUTO-COMPLETE FUNCTIONS */

    getBanks(): any[] {
        let bankOptions = [];
        for (let bank of this.jsonDataService.data.banks) {
            bankOptions.push({
                value: bank.bankCd,
                text: bank.bankName,
                alfesTag: bank.alfesTag || 'N'
            });
        }
        bankOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return bankOptions;
    }
    setBankViaInput(event: any): void {
        this.formService.setFieldToUpperCase(this.form, "bankDetail", true, event);
        this.resetBankDependent();
        for (const bank of this.getBanks()) {
            if (bank.text === this.form.controls["bankDetail"].value.text) {
                this.form.controls["bankDetail"].setValue(bank);
                this.validatedBank();
            }
        }
    }
    setBankViaOption(option: any): void {
        this.resetBankDependent();
        this.validatedBank();
    }
    validatedBank(): void {
        const bankAlfesTag = this.form.controls["bankDetail"].value.alfesTag;
        this.showBankNameDependent = bankAlfesTag === 'Y';
        this.setFieldRequired("alfesNo");
    }

    /* YEAR MODEL AUTO-COMPLETE FUNCTIONS */

    getYearModels(minPreviousYear: number = -1): any[] {
        let modelYears = [];
        let yearModelOptions = [];
        for (let veDetail of this.jsonDataService.data.veDetailsOSP.filter((a:any)=>this.dateToday >= new Date(a.effDateFrom) && (!a.effDateTo || this.dateToday <= new Date(a.effDateTo)))) {
            const satisfiedMinPrevYear = minPreviousYear === -1 || veDetail.modelYear > (new Date().getFullYear() - minPreviousYear);
            if ((this.productSublineCd === "CTPL" || veDetail.fmvValue > 0) && modelYears.indexOf(veDetail.modelYear) === -1 && satisfiedMinPrevYear && 
            (
                this.includedCarCompanyCd.includes(veDetail.carCompanyCd) &&
                this.includedMakeCd.includes(veDetail.makeCd) &&
                this.includeSeriesCd.includes(veDetail.seriesCd)
            )) {
                modelYears.push(veDetail.modelYear);
                yearModelOptions.push({
                    value: veDetail.modelYear,
                    text: String(veDetail.modelYear)
                });
            }
        }
        yearModelOptions.sort((a: any, b: any) => b.text.localeCompare(a.text));
        return yearModelOptions;
    }
    getMinPreviousYear(): number {
        let minPreviousYear = -1;
        // if (this.form.controls["vehicleType"].value === "1") {
        //     if (this.productSublineCd === "CTPL") {
        //         minPreviousYear = this.parameterService.paramN("FILTER_CTPL_YRS_NEW_VEHICLE");
        //     } else if (this.productSublineCd === "COM") {
        //         minPreviousYear = this.parameterService.paramN("FILTER_COM_YRS_NEW_VEHICLE");
        //     }
        // } else if (this.form.controls["vehicleType"].value === "2") {
        //     if (this.productSublineCd === "CTPL") {
        //         minPreviousYear = this.parameterService.paramN("FILTER_CTPL_YRS_USED_VEHICLE");
        //     } else if (this.productSublineCd === "COM") {
        //         minPreviousYear = this.parameterService.paramN("FILTER_COM_YRS_USED_VEHICLE");
        //     }        
        // }
        let filter = this.jsonDataService.data.filterPerPlan.filter((plan:any)=>this.productInfo.lineCd==plan.lineCd && this.productSublineCd == plan.sublineCd && this.productInfo.planInfo.planCd == plan.planCd)[0];
        if (this.form.controls["vehicleType"].value === "1") {
            minPreviousYear = filter?.filterYearNew;
        }else{
            minPreviousYear = filter?.filterYearUsed;
        }
        return minPreviousYear || -1;
    }
    setYearModelOptions(): void {
        this.filteredYearModelDetailOptions = this.autocompleteService.set(this.form, "yearModelDetail", this.getYearModels(this.getMinPreviousYear()));
        console.log("filteredYearModelDetailOptions", this.filteredYearModelDetailOptions);
        if (this.form.controls["yearModelDetail"].value) {
            let yearFound = false;
            for (const year of this.getYearModels(this.getMinPreviousYear())) {
                if (year.text === this.form.controls["yearModelDetail"].value.text) {
                    yearFound = true;
                    break;
                }
            }

            if (yearFound) {
                this.form.controls["yearModelDetail"].setErrors(null);
            } else {
                this.form.controls["yearModelDetail"].setErrors({ "autocomplete": true });
                this.form.controls["yearModelDetail"].markAsTouched();
            }
        }
    }
    setYearModelViaInput(): void {
        this.resetMakeDetail();
        for (const year of this.getYearModels(this.getMinPreviousYear())) {
            if (year.text === this.form.controls["yearModelDetail"].value) {
                this.form.controls["yearModelDetail"].setValue(year);
                this.validatedYearModel();
            }
        }
    }
    setYearModelViaOption(option: any): void {
        this.resetMakeDetail();
        this.validatedYearModel();
    }
    validatedYearModel(): void {
        this.form.controls["makeDetail"].enable();
        this.filteredMakeDetailOptions = this.autocompleteService.set(this.form, "makeDetail", this.getMakes());
    }

    /* MAKE AUTO-COMPLETE FUNCTIONS */

    setMakePlaceholder(): string {
        if (typeof this.form.controls["yearModelDetail"].value !== "object"
            || this.formService.isInvalid(this.form, "yearModelDetail")) {
            return "Please select Year Model";
        } else {
            return "Brand";
        }
    }
    getMakes(): any[] {
        let carCompanyCodes = [];
        let carCompanyOptions = [];
        for (let veDetail of this.jsonDataService.data.veDetailsOSP.filter((a: any) =>
            a.modelYear === this.form.controls["yearModelDetail"].value.value && (this.productSublineCd === "CTPL" || a.fmvValue > 0) && 
            this.dateToday >= new Date(a.effDateFrom) && (!a.effDateTo || this.dateToday <= new Date(a.effDateTo)) &&
            (
                this.includedCarCompanyCd.includes(a.carCompanyCd) &&
                this.includedMakeCd.includes(a.makeCd) &&
                this.includeSeriesCd.includes(a.seriesCd)
            )
        )) {
            if (carCompanyCodes.indexOf(veDetail.carCompanyCd) === -1) {
                carCompanyCodes.push(veDetail.carCompanyCd);
                for (let carCompany of this.jsonDataService.data.carCompanies.filter((a: any) =>
                    a.carCompanyCd === veDetail.carCompanyCd
                )) {
                    for (let includedVehicle of this.includedVehicle) {
                        if (includedVehicle.carCompanyCd === carCompany.carCompanyCd) {
                            carCompanyOptions.push({
                                value: carCompany.carCompanyCd,
                                text: carCompany.carCompany
                            });
                            break;
                        }
                    }
                }
            }
        }

        if (carCompanyOptions.length === 0) {
            carCompanyOptions.push({
                value: "",
                text: "No Available Brand"
            });
        }

        carCompanyOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return carCompanyOptions;
    }
    setMakeViaInput(event: any): void {
        this.formService.setFieldToUpperCase(this.form, "makeDetail", true, event);
        this.resetModelDetail();
        for (const make of this.getMakes()) {
            if (make.text === this.form.controls["makeDetail"].value.text) {
                this.form.controls["makeDetail"].setValue(make);
                this.validatedMake();
            }
        }
    }
    setMakeViaOption(option: any): void {
        this.resetModelDetail();
        this.validatedMake();
        console.log("this.validatedMake", this.validatedMake);
    }
    validatedMake(): void {
        this.form.controls["modelDetail"].enable();
        this.filteredModelDetailOptions = this.autocompleteService.set(this.form, "modelDetail", this.getModels());
        console.log(" this.filteredModelDetailOptions",  this.filteredModelDetailOptions);
    }
    resetMakeDetail(): void {
        this.form.controls["makeDetail"].disable();
        this.form.controls["makeDetail"].setValue("");
        this.resetModelDetail();
    }

    /* MODEL AUTO-COMPLETE FUNCTIONS */

    setModelPlaceholder(): string {
        if (typeof this.form.controls["makeDetail"].value !== "object"
            || this.formService.isInvalid(this.form, "makeDetail")) {
            return "Please select Brand";
        } else {
            return "Model";
        }
    }
    getModels(): any[] {
        let modelCodes = [];
        let modelOptions = [];
    
        console.log('Starting getModels function');
    
        for (let veDetail of this.jsonDataService.data.veDetailsOSP.filter((a: any) =>
            a.modelYear === this.form.controls["yearModelDetail"].value.value
            && a.carCompanyCd === this.form.controls["makeDetail"].value.value
            && (this.productSublineCd === "CTPL") &&
            this.dateToday >= new Date(a.effDateFrom) && (!a.effDateTo || this.dateToday <= new Date(a.effDateTo)) &&
            (
                this.includedCarCompanyCd.includes(a.carCompanyCd) &&
                this.includedMakeCd.includes(a.makeCd) &&
                this.includeSeriesCd.includes(a.seriesCd)
            )
        )) {
            console.log('Processing veDetail:', veDetail);
    
            if (modelCodes.indexOf(veDetail.makeCd) === -1) {
                modelCodes.push(veDetail.makeCd);
                console.log('Unique modelCodes found, pushing:', veDetail.makeCd);
    
                for (let make of this.jsonDataService.data.makes.filter((a: any) =>
                    a.carCompanyCd === veDetail.carCompanyCd
                    && a.makeCd === veDetail.makeCd
                )) {
                    console.log('Processing make:', make);
    
                    for (let includedVehicle of this.includedVehicle) {
                        if (includedVehicle.carCompanyCd === make.carCompanyCd && includedVehicle.makeCd === make.makeCd) {
                            modelOptions.push({
                                value: make.makeCd,
                                text: make.makeDesc
                            });
    
                            console.log('Pushed to modelOptions:', {
                                value: make.makeCd,
                                text: make.makeDesc
                            });
    
                            break;
                        }
                    }
                }
            }
        }
    
        if (modelOptions.length === 0) {
            console.log('No modelOptions found, adding default option');
            modelOptions.push({
                value: "",
                text: "No Available Model"
            });
        }
    
        console.log('Before sorting, modelOptions:', modelOptions);
    
        modelOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
    
        console.log('After sorting, modelOptions:', modelOptions);
    
        return modelOptions;
    }
    
    setModelViaInput(event: any): void {
        this.formService.setFieldToUpperCase(this.form, "modelDetail", true, event);
        this.resetSubModelDetail();
        for (const model of this.getModels()) {
            if (model.text === this.form.controls["modelDetail"].value.text) {
                this.form.controls["modelDetail"].setValue(model);
                this.validatedModel();
            }
        }
    }
    setModelViaOption(option: any): void {
        this.resetSubModelDetail();
        this.validatedModel();
    }
    validatedModel(): void {
        this.form.controls["subModelDetail"].enable();
        this.filteredSubModelDetailOptions = this.autocompleteService.set(this.form, "subModelDetail", this.getSubModels());
    }
    resetModelDetail(): void {
        this.form.controls["modelDetail"].disable();
        this.form.controls["modelDetail"].setValue("");
        this.resetSubModelDetail();
    }

    /* SUB-MODEL AUTO-COMPLETE FUNCTIONS */

    setSubModelPlaceholder(): string {
        if (typeof this.form.controls["modelDetail"].value !== "object"
            || this.formService.isInvalid(this.form, "modelDetail")) {
            return "Please select Model";
        } else {
            return "Variant";
        }
    }
    getSubModels(): any[] {
        let subModelCodes = [];
        let subModelOptions = [];
    
        console.log('Starting getSubModels function');
    
        for (let veDetail of this.jsonDataService.data.veDetailsOSP.filter((a: any) =>
            a.modelYear === this.form.controls["yearModelDetail"].value.value
            && a.carCompanyCd === this.form.controls["makeDetail"].value.value
            && a.makeCd === this.form.controls["modelDetail"].value.value
            && (this.productSublineCd === "CTPL" || a.fmvValue > 0) &&
            this.dateToday >= new Date(a.effDateFrom) && (!a.effDateTo || this.dateToday <= new Date(a.effDateTo)) &&
            (
                this.includedCarCompanyCd.includes(a.carCompanyCd) &&
                this.includedMakeCd.includes(a.makeCd) &&
                this.includeSeriesCd.includes(a.seriesCd)
            )
        )) {
            console.log('Processing veDetail:', veDetail);
    
            if (subModelCodes.indexOf(veDetail.seriesCd) === -1) {
                subModelCodes.push(veDetail.seriesCd);
    
                console.log('Unique subModelCodes found, pushing:', veDetail.seriesCd);
    
                for (let series of this.jsonDataService.data.engineSeries.filter((a: any) =>
                    a.carCompanyCd === veDetail.carCompanyCd
                    && a.makeCd === veDetail.makeCd
                    && a.seriesCd === veDetail.seriesCd
                )) {
                    console.log('Processing series:', series);
    
                    for (let includedVehicle of this.includedVehicle) {
                        if (includedVehicle.carCompanyCd === series.carCompanyCd && includedVehicle.makeCd === series.makeCd && includedVehicle.seriesCd === series.seriesCd) {
                            subModelOptions.push({
                                value: series.seriesCd,
                                text: series.engineSeries,
                                makeTypeCd: series.makeTypeCd,
                                ccWt: series.ccWt,
                                seats: series.seats,
                                gasType: series.gasType,
                                fmvMinValue: String(veDetail.fmvValueMin),
                                fmvMaxValue: String(veDetail.fmvValueMax),
                                contractTypeCd: series.contractTypeCd
                            });
    
                            console.log('Pushed to subModelOptions:', {
                                value: series.seriesCd,
                                text: series.engineSeries,
                                makeTypeCd: series.makeTypeCd,
                                ccWt: series.ccWt,
                                seats: series.seats,
                                gasType: series.gasType,
                                fmvMinValue: String(veDetail.fmvValueMin),
                                fmvMaxValue: String(veDetail.fmvValueMax),
                                contractTypeCd: series.contractTypeCd
                            });
                            console.log("returning submodelOptions", subModelOptions);
                            return subModelOptions;
                            // break;
                        }
                    }
                }
            }
        }
    
        if (subModelOptions.length === 0) {
            console.log('No subModelOptions found, adding default option');
            subModelOptions.push({
                value: "",
                text: "No Available Variant"
            });
        }
    
        console.log('Final subModelOptions:', subModelOptions);

        subModelOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return subModelOptions;
    }
    setSubModelViaInput(event: any): void {
        this.formService.setFieldToUpperCase(this.form, "subModelDetail", true, event);
        this.resetSubModelDependents();
        for (const submodel of this.getSubModels()) {
            if (submodel.text === this.form.controls["subModelDetail"].value.text) {
                this.form.controls["subModelDetail"].setValue(submodel);
                this.validatedSubModel(
                    submodel.makeTypeCd,
                    submodel.ccWt,
                    submodel.gasType,
                    submodel.seats,
                    submodel.fmvMinValue,
                    submodel.fmvMaxValue,
                    submodel.contractTypeCd
                );
            }
        }
    }
    setSubModelViaOption(option: any): void {
        const optionEl = option._element.nativeElement;
        this.validatedSubModel(
            optionEl.getAttribute("makeTypeCd"),
            Number(optionEl.getAttribute("ccWt")),
            optionEl.getAttribute("gasType"),
            Number(optionEl.getAttribute("seats")),
            Number(optionEl.getAttribute("fmvMinValue")),
            Number(optionEl.getAttribute("fmvMaxValue")),
            Number(optionEl.getAttribute("contractTypeCd"))
        );
    }
    validatedSubModel(
        subModelMakeTypeCd: string,
        subModelCcWt: number,
        subModelGasType: string,
        subModelSeats: number,
        fmvMinValue: number,
        fmvMaxValue: number,
        contractTypeCd: number
    ): void {
        this.form.controls["makeTypeCd"].setValue(String(this.form.controls["yearModelDetail"].value.value).slice(-2) + subModelMakeTypeCd);
        this.form.controls["ccWt"].setValue(subModelCcWt? subModelCcWt : "-");
        this.form.controls["gasType"].setValue(subModelGasType || "");
        this.form.controls["gasDesc"].setValue(subModelGasType === "G" ? "Gas" : subModelGasType === "D" ? "Diesel" : "No Info");
        this.form.controls["noOfSeats"].setValue(subModelSeats);
        this.form.controls["seatsCovered"].setValue(subModelSeats);
        this.form.controls["fmvMinValue"].setValue(fmvMinValue);
        this.form.controls["fmvMaxValue"].setValue(fmvMaxValue);
        this.form.controls["purchasePrice"].enable();
        this.form.controls["seatsCovered"].enable();
        if(this.productSublineCd == 'CTPL'){
            this.contractTypeCd = contractTypeCd;
            let mvTypes = this.getMvTypes();
            this.form.get("mvTypeDetail")?.setValue(mvTypes[0]);
            this.filteredMvTypeOptions = this.autocompleteService.set(this.form, "mvTypeDetail", mvTypes);
        }

        if ((this.showVehicleTypeDependent || this.showFmvUsed)
            && this.productSublineCd !== 'CTPL') {
            this.fmvValue = this.getFMVValue();

            if ((this.showVehicleTypeDependent && this.parameterService.paramV('VIEW_DEF_PURCHASE_PRICE')?.split(',')?.indexOf(this.userDetailsService.accountType || 'P') != -1) ||
                    (!this.showVehicleTypeDependent && this.showFmvUsed)) {
                this.form.controls["purchasePrice"].setValue(this.fmvValue,{emitEvent:false});
                setTimeout(() => {
                    this.purchasePriceInput?.nativeElement.focus();
                    this.purchasePriceInput?.nativeElement.blur();
                    if(!this.editFmvUsed && !this.showVehicleTypeDependent){
                        this.form.controls["purchasePrice"].disable();
                    }
                }, 0)
            }

        }
    }
    resetSubModelDetail(): void {
        this.form.controls["subModelDetail"].disable();
        this.form.controls["subModelDetail"].setValue("");
        this.form.controls["mvTypeDetail"].setValue("");
        this.resetSubModelDependents();
    }
    resetSubModelDependents(): void {
        this.form.controls["makeTypeCd"].setValue("");
        this.form.controls["ccWt"].setValue("");
        this.form.controls["gasType"].setValue("");
        this.form.controls["gasDesc"].setValue("");
        this.form.controls["noOfSeats"].setValue("");
        this.form.controls["seatsCovered"].disable();
        this.form.controls["seatsCovered"].setValue("");
        this.form.controls["fmvMinValue"].setValue("");
        this.form.controls["fmvMaxValue"].setValue("");
        this.form.controls["purchasePrice"].disable();
        this.form.controls["purchasePrice"].setValue("");
    }

    /* MV PREM TYPE AUTO-COMPLETE FUNCTIONS */

    getMvPremTypes(): any[] {
        let mvPremTypes = this.jsonDataService.data.mvPremTypePerPlan.filter((mv:any)=>
            this.productInfo.lineCd==mv.lineCd && this.productSublineCd == mv.sublineCd && this.productInfo.planInfo.planCd == mv.planCd && this.form.get("vehicleType")?.value == mv.veTypeTag
        ).map((mv:any)=>mv.mvPremType);
        let mvPremTypeOptions = [];
        for (let mvPremType of this.jsonDataService.data.mvPremType) {
            if(mvPremTypes.indexOf(mvPremType.mvPremType)==-1){
                continue;
            }
            const mvYear = mvPremType.noOfYears > 1 ? " years" : " year";
            mvPremTypeOptions.push({
                value: mvPremType.mvPremType,
                text: mvPremType.mvPremTypeDesc + " - " + mvPremType.noOfYears + mvYear
            });
        }
        mvPremTypeOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return mvPremTypeOptions;
    }
    setMVPremTypeViaInput(): void {
        for (const mvPremType of this.getMvPremTypes()) {
            if (mvPremType.text === this.form.controls["mvPremTypeDetail"].value) {
                this.form.controls["mvPremTypeDetail"].setValue(mvPremType);
            }
        }
    }

    initializeYearModelDependentsAutocomplete(): void {
        if (this.form.controls["yearModelDetail"].value !== "") {
            this.form.controls["makeDetail"].enable();
            this.filteredMakeDetailOptions = this.autocompleteService.set(this.form, "makeDetail", this.getMakes());
        }

        if (this.form.controls["makeDetail"].value !== "") {
            this.form.controls["modelDetail"].enable();
            this.filteredModelDetailOptions = this.autocompleteService.set(this.form, "modelDetail", this.getModels());
        }

        if (this.form.controls["modelDetail"].value !== "") {
            this.form.controls["subModelDetail"].enable();
            this.filteredSubModelDetailOptions = this.autocompleteService.set(this.form, "subModelDetail", this.getSubModels());
        }

        if (this.form.controls["subModelDetail"].value !== "") {
            if(this.showVehicleTypeDependent || this.parameterService.paramV('EDIT_FMV_FOR_USED')?.split(',')?.indexOf(this.userDetailsService.accountType || 'P') != -1){
                this.form.controls["purchasePrice"].enable();
            }
            this.form.controls["seatsCovered"].enable();
        }
    }

    /* VEHICLE BEING USED AUTO-COMPLETE FUNCTIONS */

    getVEUsages(): any[] {
        let veUsageOptions = [];
        for (let veUsage of this.jsonDataService.data.veUsages) {
            veUsageOptions.push({
                value: veUsage.veUsageCd,
                text: veUsage.veUsageDesc
            });
        }
        veUsageOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return veUsageOptions;
    }

    /* MV TYPE AUTO-COMPLETE FUNCTIONS */

    getMvTypes(): any[] {
        let mvTypes = this.jsonDataService.data.mvTypePerPlan.filter((mv:any)=>
            this.productInfo.lineCd==mv.lineCd && this.productSublineCd == mv.sublineCd && this.productInfo.planInfo.planCd == mv.planCd && this.contractTypeCd == mv.contractTypeCd
        ).map((mv:any)=>mv.mvType);
        let mvTypeOptions = [];
        for (let mvType of this.jsonDataService.data.mvType) {
            if(mvTypes.indexOf(mvType.mvType)>=0)
                mvTypeOptions.push({
                    value: mvType.mvType,
                    text: mvType.mvTypeDesc
                });
        }
        mvTypeOptions.sort((a: any, b: any) => a.text.localeCompare(b.text));
        return mvTypeOptions;
    }

    contactBPI(): void {
        let form = this.form.value;
        const url = this.router.serializeUrl(
            this.router.createUrlTree([`/contact-us`], {
                    queryParams: {
                        brand: form.makeDetail?.text || '',
                        yearModel: form.yearModelDetail?.value || '',
                        model: (form.modelDetail?.text || '') + ' ' + (form.subModelDetail?.text || ''),
                        srp: form.purchasePrice
                    }
                }
            )
        );
        this.router.navigateByUrl(url);
    }

    getFMVValue():number{
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        today.setHours(24, 0, 0, 0);
        let fmvValue = this.jsonDataService.data.veDetailsOSP.filter((a: any) =>{
            a.effDateFrom = new Date(a.effDateFrom);
            a.effDateFrom.setHours(0, 0, 0, 0);
            if(a.effDateTo){
                a.effDateTo = new Date(a.effDateTo);
                a.effDateTo.setHours(0, 0, 0, 0);
            }
            return a.modelYear === this.form.controls["yearModelDetail"].value.value
                && a.carCompanyCd === this.form.controls["makeDetail"].value.value
                && a.makeCd === this.form.controls["modelDetail"].value.value
                && a.seriesCd === this.form.controls["subModelDetail"].value.value
                && (a.effDateFrom) <= today
                && (!a.effDateTo || (a.effDateTo) >= today)
        });
        fmvValue = fmvValue[0];
        return fmvValue?.fmvValue || 0;
    }

    supportingDocCheck() {
        const lineCd = this.productInfo.lineCd || "";
        const sublineCd = this.productInfo.sublineCd || "";
        const planCd = this.productInfo.planInfo.planCd || "";
        const plans = this.jsonDataService.data.plans.filter((plans: any) => {
        return plans.supportDocTag && plans.lineCd === lineCd && plans.sublineCd === sublineCd && plans.planCd === planCd
        }).map((supportDocTag: any) => {
        return supportDocTag.supportDocTag
        })[0] || "";
        this.displaySupportingDoc = plans === "Y" ? true : false;
    }
}